import api from '@/api';

const state = {
    socket: null,
    subscriptions: new Map(),
    pendingSubscriptions: [],
    isLive: true,
    price: {
        sol_price: null,
    },
    messageQueue: {
        general: [],
        info: [],
        notify: [],
        coin: []
    },
    isProcessingQueue: {
        general: false,
        info: false,
        notify: false,
        coin: false
    },
    isConnected: false,

    coinsNewMessages: {},
    coinsUpdatedMessages: {},
    coinsRecentMessages: {},

    coinDataTradesMessages: {},
    coinDataChartMessages: {},
    coinDataHoldersMessages: {},
    coinDataOrdersMessages: {},

    walletTransfersMessages: {},

    notifyMessages: {},
    recentNotifications: [],
};

const mutations = {

    SET_CONNECTED(state, status) {
        state.isConnected = status;
    },
    SET_SOCKET(state, socket) {
        state.socket = socket;
    },
    SET_LIVE_STATUS(state, status) {
        state.isLive = status;
    },

    ADD_TO_QUEUE(state, { channel, message }) {
        state.messageQueue[channel].push(message);
    },
    REMOVE_FROM_QUEUE(state, channel) {
        if (!state.messageQueue[channel]) return;
        state.messageQueue[channel].shift();
    },
    SET_PROCESSING_QUEUE(state, { channel, status }) {
        if (!state.isProcessingQueue[channel]) return;
        state.isProcessingQueue[channel] = status;
    },

    ADD_SUBSCRIPTION(state, { subscriptionId, subscription }) {
        state.subscriptions.set(subscriptionId, subscription);
    },
    REMOVE_SUBSCRIPTION(state, subscriptionId) {
        state.subscriptions.delete(subscriptionId);
    },
    CLEAR_SUBSCRIPTIONS(state) {
        state.subscriptions.clear();
    },

    WS_COIN_NEW_UPDATE(state, data) {
        // alert('WS_COIN_LIST_UPDATE: '+JSON.stringify(data));
        console.log('[WS] WS_COIN_NEW_UPDATE with subscriptionId = ', JSON.stringify(data.subscriptionId));
        state.coinsNewMessages[data.subscriptionId] = data;
    },

    WS_COIN_DATA_UPDATE(state, data) {
        // alert('WS_COIN_LIST_UPDATE: '+JSON.stringify(data));
        console.log('[WS] Received coin_data update:', JSON.stringify(data));
        // console.log('[WS] WS_COIN_DATA_UPDATE with subscriptionId = ', JSON.stringify(data.subscriptionId));
        state.coinsUpdatedMessages[data.subscriptionId] = data; // subscriptionId is udentified !!

    },

    WS_COIN_RECENT_UPDATE(state, data) {
        console.log('[WS] Received coin_recent update:', JSON.stringify(data));
        state.coinsRecentMessages[data.subscriptionId] = data;
    },

    WS_COIN_DATA_TRADES_UPDATE(state, data) {
        // Store trade update in coinDataTradesMessages using subscriptionId
        state.coinDataTradesMessages[data.subscriptionId] = data;
        // was in WS_TRADES_UPDATE
        // const subscriptionId = `trades-${data.coinId}`;
        // state.tradesUpdates[subscriptionId] = {
        //     ...data,
        //     timestamp: new Date().getTime()
        // };
    },
    WS_COIN_DATA_CHART_UPDATE(state, data) {
        state.coinDataChartMessages[data.subscriptionId] = data;
    },
    WS_COIN_DATA_HOLDERS_UPDATE(state, data) {
        state.coinDataHoldersMessages[data.subscriptionId] = data;
    },

    WS_COIN_DATA_ORDERS_UPDATE(state, data) { // ## [Private]
        console.log('[WS] Received order update:', data);
        // Handle both all-orders and coin-specific orders
        if (data.subscriptionId === 'all-orders') {
            // For all-orders, store updates by coin ID
            if (data.orders && data.orders.length > 0) {
                data.orders.forEach(order => {
                    const coinSpecificId = `orders-${order.coin_id}`;
                    state.coinDataOrdersMessages[coinSpecificId] = {
                        ...data,
                        subscriptionId: coinSpecificId,
                        orders: [order]
                    };
                });
            }
        } else {
            // Handle coin-specific order updates as before
            state.coinDataOrdersMessages[data.subscriptionId] = data;
        }
    },

    WS_WALLET_TRANSFERS_UPDATE(state, data) { // ## [Private]
        // Store the entire message with transfers array
        if (data.channel === 'user_transfers') {
            state.walletTransfersMessages[data.subscriptionId] = {
                channel: data.channel,
                transfers: data.transfers || [],
                subscriptionId: data.subscriptionId
            };
        }
    },

    WS_NOTIFY_UPDATE(state, data) {
        console.log('[WS] Received notify update:', data);
        if (data.channel === 'notify' && data.data?.notifData) {
            const notifData = data.data.notifData;
            // Store notification
            state.notifyMessages[data.subscriptionId] = {
                channel: data.channel,
                notify: notifData,
                subscriptionId: data.subscriptionId
            };
            
            // Add new notification to the recent notifications list
            state.recentNotifications.unshift(notifData);
            // Keep only last 10 notifications
            if (state.recentNotifications.length > 10) {
                state.recentNotifications.pop();
            }
            
            // Add to queue for processing
            state.messageQueue.notify.push(notifData);
        }
    },

    SET_RECENT_NOTIFICATIONS(state, notifications) {
        state.recentNotifications = notifications;
    },

    SET_SOL_PRICE(state, price) {
        state.price.sol_price = price.toFixed(2);
    },

    // UPDATE_COINS(state, coinData) {
    //     // Store the coin update in coinsUpdatedMessages
    //     state.coinsUpdatedMessages = {
    //         ...state.coinsUpdatedMessages,
    //         [coinData.coin_id]: coinData
    //     };
    // }

};

const actions = {
    initWebSocket({ commit, dispatch, state }) {
        console.log('[WS] Initializing WebSocket connection...');
        const socket = new WebSocket(process.env.VUE_APP_WS_URL);
        
        socket.onmessage = (event) => {
            if (!state.isLive) {
                console.log('[WS] Message received but live updates are disabled');
                return;
            }
            
            const data = JSON.parse(event.data);
            console.log('[WS] Message received:', data);

            switch (data.type) {
                case 'welcome':
                    console.log('[WS] Welcome message:', data.message);
                    commit('SET_CONNECTED', true);
                    dispatch('subscribeToInfo');


                    break;
                case 'error':
                    console.error('[WS] Server error:', data.message);
                    break;
                case 'subscribed':
                    console.log('[WS] Successfully subscribed:', data.subscriptionId);
                    break;
                case 'unsubscribed':
                    console.log('[WS] Successfully unsubscribed:', data.subscriptionId);
                    break;
                default:
                    if (data.channel) {
                        console.log('[WS] Dispatching message on channel:', data.channel);
                        switch (data.channel) {

                            // Public channels, no subscription
                            case 'general':
                                dispatch('handleGeneralMessage', data);
                                break;
                            case 'price':
                                dispatch('handlePriceMessage', data);
                                break;

                            // ## Public channels, requires subscription
                            case 'info':
                                dispatch('handleInfoMessage', data);
                                break;

                            // ## Public channel, all coins updates (social actions and trades) to show notification and append a coin the list
                            case 'new_coin':
                                commit('WS_COIN_NEW_UPDATE', data);
                                break;
                            case 'coin_data': // ## Received new order, or social action, contains updated data for coin in a list or on a page
                                dispatch('handleCoinDataMessage', data);
                                break;        

                            // ## Public channels, requires subscription with specific coin Id (while on a coin page)
                            case 'coin_trades':
                                commit('WS_COIN_DATA_TRADES_UPDATE', data);
                                break;
                            case 'coin_charts':
                                commit('WS_COIN_DATA_CHART_UPDATE', data);
                                break;
                            // case 'coin_holders':
                            //     commit('WS_COIN_DATA_HOLDERS_UPDATE', data);
                            //     break;

                            // ## Private channels
                            case 'user_orders': // ## [Private] - orders for this user
                                commit('WS_COIN_DATA_ORDERS_UPDATE', data);
                                break;
                            case 'user_transfers': // ## [Private] - transfers for this user
                                commit('WS_WALLET_TRANSFERS_UPDATE', data);
                                break;
                            case 'user_notify': // ## [Private] - notifications for this user
                                dispatch('handleNotifyMessage', data);
                                break;
                            case 'coin_recent': // ## [Private] - notifications for this user
                                dispatch('handleRecentMessage', data);
                                break;

                        }
                    }
            }
        };

        socket.onopen = () => {
            console.log('[WS] Connection established');
            commit('SET_CONNECTED', true);
            
            // Process any pending subscriptions
            state.pendingSubscriptions.forEach(sub => {
                dispatch('subscribe', sub);
            });
            state.pendingSubscriptions = [];
        };

        socket.onerror = (error) => {
            console.error('[WS] WebSocket error:', error);
            commit('SET_CONNECTED', false);
        };

        socket.onclose = () => {
            console.log('[WS] Connection closed');
            commit('SET_CONNECTED', false);
            setTimeout(() => dispatch('initWebSocket'), 5000);
        };

        commit('SET_SOCKET', socket);
    },

    subscribeToInfo({ state, dispatch }) {
        dispatch('subscribe', {
            subscriptionId: 'info-main',
            subscription: {
                type: 'info'
            }
        });
    },

    subscribe({ state, commit }, { subscriptionId, subscription }) {
        if (!state.socket || state.socket.readyState !== WebSocket.OPEN) {
            console.log('[WS] Socket not ready, queueing subscription:', { subscriptionId, subscription });
            state.pendingSubscriptions.push({ subscriptionId, subscription });
            return;
        }

        const message = {
            type: 'subscribe',
            subscriptionId,
            subscription
        };
        
        console.log('[WS] Subscribing:', message);
        state.socket.send(JSON.stringify(message));
        commit('ADD_SUBSCRIPTION', { subscriptionId, subscription });
    },

    unsubscribe({ state, commit }, subscriptionId) {
        if (!state.socket || state.socket.readyState !== WebSocket.OPEN) return;

        state.socket.send(JSON.stringify({
            channel: 'unsubscribe',
            subscriptionId
        }));
        commit('REMOVE_SUBSCRIPTION', subscriptionId);
    },

    // ** Messages Queue ** //

    handleGeneralMessage({ commit, dispatch }, message) {
        commit('ADD_TO_QUEUE', { channel: 'general', message });
        dispatch('processQueue', 'general');
    },

    handleInfoMessage({ commit, dispatch }, message) {
        commit('ADD_TO_QUEUE', { channel: 'info', message });
        dispatch('processQueue', 'info');
    },

    handleRecentMessage({ commit, dispatch }, message) {
        commit('WS_COIN_DATA_UPDATE', message);
        commit('ADD_TO_QUEUE', { channel: 'coin', message });
        dispatch('processQueue', 'coin');
    },

    handleNotifyMessage({ commit, dispatch }, message) {
        commit('WS_NOTIFY_UPDATE', message);
        commit('ADD_TO_QUEUE', { channel: 'notify', message });
        dispatch('processQueue', 'notify');
    },

    handleCoinDataMessage({ commit, dispatch }, message) {
        commit('WS_COIN_DATA_UPDATE', message);
        commit('ADD_TO_QUEUE', { channel: 'coin', message });
        dispatch('processQueue', 'coin');
    },

    async processQueue({ state, commit, dispatch }, channel) {
        if (state.isProcessingQueue[channel] || state.messageQueue[channel].length === 0) return;

        commit('SET_PROCESSING_QUEUE', { channel, status: true });
        const message = state.messageQueue[channel][0];

        switch (channel) {
            case 'general':
                await dispatch('showNotification', {
                    message: message.text,
                    type: message.type
                }, { root: true });
                break;
            case 'info':
                await dispatch('showInformation', {
                    message: message.text,
                    type: message.type
                }, { root: true });
                break;
            case 'coin': // collected from recent coins and coin data updates
                let text='';
                message.coin.coin_name && (text += message.coin.coin_name + ' ');
                message.coin.coin_ticker && (text += '$'+message.coin.coin_ticker + ' ');
                if ( message.data?.update_type === 'preorder' ) {
                    text += 'Preorder received for ';
                    text += '<b>'+ message.data?.update_amount + ' SOL</b> ';
                }
                // if ( message.data.update_type === 'social' ) {
                //     text += message.data.social + ' ';
                // }
                
                await dispatch('showInformation', {
                    message: text,
                    type: 'info'
                }, { root: true });
                break;
            case 'notify':
                let notifMessage = message.notif_text || '';
                let type = 'info';

                // Set message type based on notification type
                if ([1210, 2120, 2130, 2220, 2230, 2510].includes(message.notif_type)) {
                    type = 'error';
                } else if ([1200, 2110, 2210, 2501, 3100].includes(message.notif_type)) {
                    type = 'success';
                } else if ([1100, 2100, 2200, 2500, 2800].includes(message.notif_type)) {
                    type = 'warning';
                }

                // Show notification
                await dispatch('showInformation', {
                    message: notifMessage,
                    type: type
                }, { root: true });

                // Refresh notifications list in Notifications.vue
                // const notificationsComponent = document.querySelector('.notifications-page');
                // if (notificationsComponent && notificationsComponent.__vue__) {
                //     notificationsComponent.__vue__.fetchNotifs();
                // }
                break;
        }

        commit('REMOVE_FROM_QUEUE', channel);
        commit('SET_PROCESSING_QUEUE', { channel, status: false });

        if (state.messageQueue[channel].length > 0) {
            dispatch('processQueue', channel);
        }
    },

    // ** Price Updates ** //

    handlePriceMessage({ commit }, message) { // price in message
        if (message.sol_price) {
            const price = parseFloat(message.sol_price);
            commit('SET_SOL_PRICE', price);
        }
    },

    // ** Subscriptions and Unsubscribe ** //
    
    setLiveStatus({ commit, dispatch }, status) {
        commit('SET_LIVE_STATUS', status);
        if (!status) {
            dispatch('unsubscribeAll');
        }
    },

    unsubscribeAll({ state, commit }) {
        if (!state.socket || state.socket.readyState !== WebSocket.OPEN) return;

        state.socket.send(JSON.stringify({
            channel: 'unsubscribeAll'
        }));
        commit('CLEAR_SUBSCRIPTIONS');
    },

    subscribeToCoinData({ dispatch }, coinId) {
        // Subscribe to chart updates
        dispatch('subscribe', {
            subscriptionId: `charts-${coinId}`,
            subscription: {
                type: 'charts',
                filters: {
                    coinId,
                    type: 'price',
                    interval: '5m',
                    range: 24
                }
            }
        });
        console.log('[WS] Subscribed to chart updates for coin:', coinId);

        // Subscribe to trades updates
        dispatch('subscribe', {
            subscriptionId: `trades-${coinId}`,
            subscription: {
                type: 'trades',
                filters: {
                    coinId
                }
            }
        });
        console.log('[WS] Subscribed to trades updates for coin:', coinId);

        // Subscribe to holders updates
        dispatch('subscribe', {
            subscriptionId: `holders-${coinId}`,
            subscription: {
                type: 'holders',
                filters: {
                    coinId
                }
            }
        });
        console.log('[WS] Subscribed to holders updates for coin:', coinId);
    },

    subscribeToRecentCoins({ dispatch, rootState }, { wsCode, coinId = null } ) {
        const sessionToken = rootState.sessionToken || null;
        // Subscribe to chart updates
        dispatch('subscribe', {
            subscriptionId: `all-coins`, // Actually, it's always subscribed to `all-orders`
            subscription: {
                type: 'coin_recent',
                session: sessionToken,
                code: wsCode,
                // filters: {
                //     coinId,
                // }   
            }
        });
        console.log('[WS] Subscribed to orders updates for user:', sessionToken);
    },

    subscribeToPrivateOrders({ dispatch, rootState }, { wsCode, coinId = null } ) {
        const sessionToken = rootState.sessionToken || null;
        // Subscribe to chart updates
        dispatch('subscribe', {
            subscriptionId: coinId ? `orders-${coinId}` : `all-orders`, // Actually, it's always subscribed to `all-orders`
            subscription: {
                type: 'user_orders',
                session: sessionToken,
                code: wsCode,
                filters: {
                    coinId,
                }   
            }
        });
        console.log('[WS] Subscribed to orders updates for user:', sessionToken);
    },

    subscribeToPrivateTransfers({ dispatch, rootState }, { wsCode, walletId = null } ) {
        const sessionToken = rootState.sessionToken || null;
        // Subscribe to chart updates
        dispatch('subscribe', {
            subscriptionId: walletId ? `wallet-${walletId}` : `all-wallets`, // Actually, it's always subscribed to `all-wallets`
            subscription: {
                type: 'user_transfers',
                code: wsCode,
                session: sessionToken,
                // filters: {
                //     walletId,
                // }
            }
        });
        console.log('[WS] Subscribed to orders updates for user:', sessionToken);
    },

    subscribeToPrivateNotifications({ dispatch, rootState }, { wsCode } ) {
        const sessionToken = rootState.sessionToken || null;
        dispatch('subscribe', {
            subscriptionId: 'all-notifications',
            subscription: {
                type: 'notify',
                code: wsCode,
                session: sessionToken,
            }
        });
    },

    unsubscribeFromCoinData({ dispatch }, coinId) {
        dispatch('unsubscribe', `charts-${coinId}`);
        dispatch('unsubscribe', `trades-${coinId}`);
        dispatch('unsubscribe', `holders-${coinId}`);
    },

    async initializeNotifications({ commit }) {
        try {
            const response = await api.post('user/notif/list', { 
                data: { limit: 10 },
            });
            if (response.data.result === 'success') {
                commit('SET_RECENT_NOTIFICATIONS', response.data.notifs);
            }
        } catch (error) {
            console.error('Error fetching initial notifications:', error);
        }
    },

};

const getters = {
    isConnected: (state) => state.socket !== null && state.socket.readyState === WebSocket.OPEN,
    recentNotifications: state => state.recentNotifications
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};