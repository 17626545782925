import store from '@/store';  // Import the store at the top

export default {
    data() {

        return {

            Language: [
                { name: 'English', code: 'en', flag: '🇺🇸' },
                { name: 'Spanish', code: 'es', flag: '🇪🇸' },
                { name: 'French', code: 'fr', flag: '🇫🇷' },
                { name: 'German', code: 'de', flag: '🇩🇪' },
                { name: 'Italian', code: 'it', flag: '🇮🇹' },
                { name: 'Portuguese', code: 'pt', flag: '🇵🇹' },
                { name: 'Russian', code: 'ru', flag: '🇷🇺' },
                { name: 'Chinese', code: 'zh', flag: '🇨🇳' },
                { name: 'Japanese', code: 'ja', flag: '🇯🇵' },
                { name: 'Korean', code: 'ko', flag: '🇰🇷' },
            ],

            BoostPacks: [
                {
                    pack_id: 1,
                    pack_name: 'Growth',
                    pack_fire: 250,
                    pack_interact: 5,
                    pack_telegram: 25,
                    pack_price: 0.19, // 0.49, is 50%
                    pack_price_old: 0.99,
                    pack_bundle: 0,
                    pack_bundle_limit: 0,
                    pack_description: 'Started boost package for your coin launch.',
                    pack_features: [
                        'Featured in "Launching Soon"',
                    ]
                },
                {
                    pack_id: 2,
                    pack_name: 'Rocket',
                    pack_fire: 1000,
                    pack_interact: 25,
                    pack_telegram: 50,
                    pack_price: 0.59, //1.29, is 50%
                    pack_price_old: 2.99,
                    pack_bundle: 1,
                    pack_bundle_limit: 3,
                    pack_description: 'Premium placement with enhanced visibility.',
                    pack_features: [
                        'Top position in "Launching Soon"',
                        'Anti-MEW bundle purchases',
                    ]
                },
                {
                    pack_id: 3,
                    pack_name: 'Moonshot',
                    pack_fire: 2500,
                    pack_interact: 50,
                    pack_telegram: 100,
                    pack_price: 0.99, //2.49, is 50%
                    pack_price_old: 4.99,
                    pack_bundle: 1,
                    pack_bundle_limit: 5,
                    pack_description: 'Maximum exposure with extensive social boost.',
                    pack_features: [
                        'Top position in "Launching Soon"',
                        'Anti-MEW bundle purchases',
                        'Featured in coins lists'
                    ]
                },
                {
                    pack_id: 4,
                    pack_name: 'Legend',
                    pack_fire: 10000,
                    pack_interact: 100,
                    pack_telegram: 250,
                    pack_price: 1.99, //4.99, is 50%
                    pack_price_old: 9.99,
                    pack_bundle: 1,
                    pack_bundle_limit: 10,
                    pack_description: 'Ultimate launch package with platform-wide promotion.', // and massive social engagement boost.
                    pack_features: [
                        'Top position in "Launching Soon"',
                        'Anti-MEW bundle purchases',
                        '<span class="text-fire">Glowing border in all lists</span>'
                    ]
                }
            ],

            NotifTypes: {
                1100: 'Launch Scheduled',
                1200: 'Coin Deployed',
                1210: 'Deployment Failed',
                1400: 'Pack Purchased',
                2100: 'Buy Order Created',
                2110: 'Buy Order Completed',
                2120: 'Buy Order Canceled',
                2130: 'Buy Order Failed',
                2200: 'Sell Order Created',
                2210: 'Sell Order Completed',
                2220: 'Sell Order Canceled',
                2230: 'Sell Order Failed',
                2500: 'Preorder Placed',
                2501: 'Tip Received',
                2510: 'Preorder Canceled',
                2800: 'Snipe Order Placed',
                2810: 'Snipe Order Canceled',
                3100: 'Transfer Completed'
            },
          
        }

    },
    methods: {

        checkEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        copyToClipboard(text) {
            navigator.clipboard.writeText(text)
            .then(() => {
                this.$store.dispatch('showNotification', {
                    message: 'Copied to clipboard',
                    type: 'success'
                });
                // console.log('Text copied to clipboard');
            })
            .catch(err => {
                // console.error('Failed to copy text: ', err);
            });
        },
        
        isActiveTab(tabName) {
            return this.$route.name === tabName;
        },

        truncateName(name) {
            if (typeof name === 'string') {
                return name.length > 20 ? name.substring(0, 20) + '...' : name;
            } else {
                return name;
            }
        },

        roundTo(num=0) {
            return Math.round(this.coin.key_price_growth,num);
        },

        getImage(img) {
            return require('@/assets/' + img);
        },

        formatWallet (walletaddress, letters = 6, hidemobile = false) {

            if(walletaddress && typeof walletaddress === 'string') { 
                // if(wallet.>40) {
                if(letters>30 || letters<2 || !letters) letters=6;
                if(hidemobile) return '<span class="max-md:hidden">'+walletaddress.substring(0, (letters))+'</span>...'+walletaddress.substr((walletaddress.length-letters))+'';
                return walletaddress.substring(0, (letters))+'...'+walletaddress.substr((walletaddress.length-letters))+'';
                //} else return wallet;
            }
            return '';

        },

        // shortenAddress(address) {
        //     return `${address.slice(0, 6)}...${address.slice(-4)}`;
        // },

        showDate(date) {
            if (!date) return '';
            
            try {
                return new Date(date).toLocaleString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    timeZone: 'UTC'
                }); //  + ' UTC';
            } catch (e) {
                console.error('Date parsing error:', e);
                return date;
            }
        },
        
        formatDate(date) {
            const now = new Date();
            const givenDate = new Date(date);
            // Convert both dates to UTC timestamps
            const nowUTC = now.getTime() + now.getTimezoneOffset() * 60000;
            const dateUTC = givenDate.getTime() + givenDate.getTimezoneOffset() * 60000;
            
            const diffInMs = Math.abs(nowUTC - dateUTC);
            const diffInDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000));
            const diffInHours = Math.floor(diffInMs / (60 * 60 * 1000));
            const diffInMinutes = Math.floor(diffInMs / (60 * 1000));

            if (diffInDays > 0) {
                return diffInDays + ' days';
            } else if (diffInHours > 0) {
                return diffInHours + ' hours';
            } else {
                return diffInMinutes + ' minutes';
            }
        },

        formatDateTime(dateString) {
            const date = new Date(dateString);
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            const timezone = date.toLocaleTimeString('en', {timeZoneName: 'short'}).split(' ')[2];
            
            return `${day}.${month}.${year} ${hours}:${minutes}:${seconds} ${timezone}`;
        },

        formatAge(date) {
            const now = new Date();
            const givenDate = new Date(date);
            // Convert both dates to UTC timestamps
            const nowUTC = now.getTime() + now.getTimezoneOffset() * 60000;
            const dateUTC = givenDate.getTime() + givenDate.getTimezoneOffset() * 60000;
            
            const diffInMs = Math.abs(nowUTC - dateUTC);
            const diffInSeconds = Math.floor(diffInMs / 1000);
            const diffInMinutes = Math.floor(diffInMs / (60 * 1000));
            const diffInHours = Math.floor(diffInMs / (60 * 60 * 1000));
            const diffInDays = Math.floor(diffInMs / (24 * 60 * 60 * 1000));

            if (diffInDays > 0) {
                return diffInDays + 'd';
            } else if (diffInHours > 0) {
                return diffInHours + 'h';
            } else if (diffInMinutes > 0) {
                return diffInMinutes + 'm';
            } else {
                return diffInSeconds + 's';
            }
        },

        getImageUrl(coinImage,path='int') {
            return `${process.env.VUE_APP_IMG_URL}${path}/${coinImage}`;
        },

        formatPrice(number, dec = 3) {
            if (number === undefined || number === null) {
                return '—';
            }

            // Convert to number if string
            const num = typeof number === 'string' ? parseFloat(number) : number;

            // Handle zero
            if (num === 0) {
                return '0';
            }

            // For very small numbers
            if (Math.abs(num) < Math.pow(10, -dec)) {
                return `<${Math.pow(10, -dec).toFixed(dec)}`;
            }

            // Format to specified decimals
            return num.toFixed(dec);
        },

        formatNumber(number, dec = 3) {
            if (number === undefined || number === null) {
                return '—';
            }

            // Convert to number if string
            const num = typeof number === 'string' ? parseFloat(number) : number;

            // Handle zero
            if (num === 0) {
                return '0';
            }

            // For very small numbers
            if (Math.abs(num) < Math.pow(10, -dec)) {
                return `<${Math.pow(10, -dec).toFixed(dec)}`;
            }

            // Format to specified decimals and trim trailing zeros
            let formatted = num.toFixed(dec);
            if (formatted.includes('.')) {
                formatted = formatted.replace(/\.?0+$/, '');
            }

            // Split into parts after trimming zeros
            const parts = formatted.split('.');
            
            // Add spacing to the whole number part
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            
            // Reconstruct the number with decimal part if it exists
            return parts.length > 1 ? parts.join('.') : parts[0];
        },

        formatMC(num) {
            if (num === undefined || num === null) {
                return '—';
            }
            if (num >= 1e9) {
                return '$'+(num / 1e9).toFixed(1) + 'B';
            } else if (num >= 1e6) {
                return '$'+(num / 1e6).toFixed(1) + 'M';
            } else if (num >= 1e3) {
                return '$'+(num / 1e3).toFixed(1) + 'K';
            } else {
                return '<$1K';
            }
        },

        formatKMB(num) {
            if (num === undefined || num === null) {
                return '—';
            }
            if(num === 0) return '0';
            if(num<1) return '1';
            if(num<1000) return num;

            let value, suffix;
            if (num >= 1e9) {
                value = (num / 1e9).toFixed(1);
                suffix = 'B';
            } else if (num >= 1e6) {
                value = (num / 1e6).toFixed(1);
                suffix = 'M';
            } else if (num >= 1e3) {
                value = (num / 1e3).toFixed(1);
                suffix = 'K';
            } else {
                return '1K';
            }

            // Split number into parts and add spacing to whole number part
            const parts = value.split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            
            // Reconstruct with suffix
            return parts.join('.') + suffix;
        },

        // formatKMB(num, decimals = 2) {
        //     if (num === null || num === undefined) return '0';
            
        //     const absNum = Math.abs(num);
        //     if (absNum >= 1e9) {
        //         return (num / 1e9).toFixed(decimals) + 'B';
        //     }
        //     if (absNum >= 1e6) {
        //         return (num / 1e6).toFixed(decimals) + 'M';
        //     }
        //     if (absNum >= 1e3) {
        //         return (num / 1e3).toFixed(decimals) + 'K';
        //     }
        //     return num.toFixed(decimals);
        // },

        tierPoints(tier) {
            if (tier === 1) return 25;
            if (tier === 2) return 10;
            if (tier === 3) return 5;
            if (tier === 4) return 1;
            if (tier === 5) return 0;
        },

        getTier(tier,text=true,small=false) {
            let per, tierName, colors, name;
      
            per = this.tierPoints(tier);
            if (tier === 1) { name= '0'; colors = 'bg-slate-700 text-green-200 border-green-300'; }
            else if (tier === 2) { name= 'I'; colors = 'bg-slate-700 text-yellow-200 border-yellow-300'; }
            else if (tier === 3) { name= 'II'; colors = 'bg-slate-700 text-orange-200 border-orange-300'; }
            else if (tier === 4) { name= 'III'; colors = 'bg-slate-700 text-red-200 border-red-300'; }
            else if (tier === 5) { name= 'V'; colors = 'bg-slate-700 text-blue-200 border-blue-300'; }
            else { colors = 'bg-gray-100 text-gray-800 border-gray-300'; }
      
            if(text===true)  tierName = tier === 0 ? 'Unknown' : `Tier ${name}`;
            else tierName = tier === 0 ? '-' : `${name}`;
            if(small===true) return `<span class="inline-flex items-center px-1.5 py-0.5 rounded-lg text-xs font-medium border ${colors}">${tierName}</span>`;
            
            return `<span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium border ${colors}">${tierName}</span>`;
        },

        getGrade(grade,huge=false) {
            let gradeName, colors, name;
      
            if (grade === 1) { name= 'Starter'; colors = 'bg-green-700 bg-opacity-50 border-green-500 text-green-100'; }
            else if (grade === 2) { name= 'Premium'; colors = 'bg-blue-700 bg-opacity-50 border-blue-400 text-blue-100'; }
            else if (grade === 3) { name= 'Gold'; colors = 'bg-yellow-600 bg-opacity-50 border-yellow-500 text-yellow-100'; }
            else if (grade === 4) { name= 'Ultimate'; colors = 'bg-gray-700 bg-opacity-50 border-gray-400 text-gray-200 '; }
            else if (grade === 5) { name= 'Legendary'; colors = 'bg-red-700 bg-opacity-50 border-red-500 text-slate-200'; }
            else { colors = 'bg-gray-800 text-gray-100'; }

            gradeName = grade === 0 ? 'Unknown' : `${name}`;
            // else gradeName = grade === 0 ? '-' : `${name}`;
      
            if(huge===true) return `<span class="inline-flex items-center px-5 py-2 rounded-full text-xl font-bold border ${colors}">${gradeName}</span>`;
            return `<span class="font-bold text-shadow-sm text-shadow-slate-950 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm border ${colors}">${gradeName}</span>`;

        },

        getGradeAccess(access,huge=false) {
            let gradeName, colors, name;
      
            if (access === 1) { name= 'Early Access'; colors = 'bg-green-700 bg-opacity-50 border-green-500 text-green-100'; }
            else if (access === 2) { name= 'Pro'; colors = 'bg-blue-700 bg-opacity-50 border-blue-400 text-blue-100'; }
            else { colors = 'bg-gray-800 text-gray-200 border-gray-600'; }

            gradeName = access === 0 ? 'Public Access' : `${name}`;
            // else gradeName = grade === 0 ? '-' : `${name}`;
      
            if(huge===true) return `<span class="inline-flex items-center px-5 py-2 rounded-full text-xl font-bold border ${colors}">${gradeName}</span>`;
            return `<span class="font-bold text-shadow-sm text-shadow-slate-950 inline-flex items-center px-2.5 py-0.5 rounded-full text-sm border ${colors}">${gradeName}</span>`;

        },


        getBoostPackName(pack_id) {
            const pack = this.BoostPacks.find(pack => pack.pack_id === pack_id);
            return pack ? pack.pack_name : 'Unknown';
        },

    formatSOL(sol,num=4) {
        // return this.formatNumber(sol,num) + ' SOL';
        return this.formatSol(sol,num);
    },

    formatSol(solBalance, max = 6) {
        // Handle string input (convert to number)
        let sol;
        if (typeof solBalance === 'string') {
            sol = parseFloat(solBalance.replace(/[^0-9.-]/g, '')) || 0;
        } else {
            sol = parseFloat(solBalance || 0);
        }
        
        // Special case for 0
        if (sol === 0) return '0.00';
        
        // Convert to fixed number with max decimals
        const fixed = sol.toFixed(max);
        
        // If no decimal point, return as is
        // if (!formatted.includes('.')) return formatted;
        
        // Remove trailing zeros after decimal point
        // But keep at least one decimal place if there is a decimal point
        const parts = fixed.split('.');
        if (parts.length === 1) return parts[0]; // No decimal point
        
        // Trim trailing zeros from decimal part
        const decimals = parts[1].replace(/0+$/, '');
        
        // If all decimals were zeros, return just the whole number
        if (decimals === '') return parts[0];
        
        // Otherwise return number with remaining decimals
        return `${parts[0]}.${decimals}`;
    },

    formatSolToUsd(solBalance, dec = 2) {

        const sol = parseFloat(solBalance || '0');
        const solPrice = parseFloat(store.state.ws.price?.sol_price || '0');
        const usdValue = sol * solPrice;
        return usdValue.toFixed(dec);

    },

        checkMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        },

        transformImageUrl(url) {
            if (!url) return '';
            try {
                // Extract IPFS hash from the URL
                const ipfsHash = url.split('/ipfs/')[1];
                if (!ipfsHash) {
                    // If no IPFS hash, use VUE_APP_IMG_URL
                    return `${process.env.VUE_APP_IMG_URL}int/${url}`;
                }
                // if (!ipfsHash) return url;
                
                // Construct new Pinata URL
                return `https://pump.mypinata.cloud/ipfs/${ipfsHash}?img-width=128&img-dpr=2&img-onerror=redirect`;
            } catch (e) {
                console.error('Error transforming image URL:', e);
                return url;
            }
        },

        getOrderStatus(status) {
            switch (status) {
                case 0:
                    return 'Awaiting';
                case 1:
                    return 'Pending';
                case 2:
                    return 'Active';
                case 3:
                    return 'Completed';
                case 4:
                    return 'Cancelled';
                default:
                    return 'Unknown';
            }
        },

        // Convert any date input to UTC Date object
        toUTCDate(date) {
            if (!date) return null;
            // If it's already a Date object, ensure it's UTC
            if (date instanceof Date) {
                return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
            }
            // If it's an ISO string with Z (UTC), parse directly
            if (typeof date === 'string' && date.endsWith('Z')) {
                return new Date(date);
            }
            // For other strings, parse and convert to UTC
            const localDate = new Date(date);
            return new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        },

        formatUTCDateTime(timestamp) {
            // If timestamp is in milliseconds (like from Date.now()), convert to Date
            const date = new Date(timestamp);
            
            // Get UTC components
            const year = date.getUTCFullYear();
            const month = String(date.getUTCMonth() + 1).padStart(2, '0');
            const day = String(date.getUTCDate()).padStart(2, '0');
            const hours = String(date.getUTCHours()).padStart(2, '0');
            const minutes = String(date.getUTCMinutes()).padStart(2, '0');
            const seconds = String(date.getUTCSeconds()).padStart(2, '0');

            // Return in Y-m-d H:i:s format
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },

    }
}