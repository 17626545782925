import { createStore } from 'vuex';

import sessActions from './modules/sess.js';
import authActions from './modules/auth.js';
import userActions from './modules/user.js';
import wsModule from './modules/ws.js';

import modalsModule from './modals.js';

import walletsModule from './wallets.js';
import metamaskModule from './wallets/metamask.js';
import phantomModule from './wallets/phantom.js';
import solflareModule from './wallets/solflare.js';
// import adapterModule from './wallets/adapter.js';

import playwalletModule from './playwallet.js';

const store = createStore({

modules: {
    modals: modalsModule,
    wallets: walletsModule,
    metamask: metamaskModule,
    phantom: phantomModule,
    solflare: solflareModule,
    // adapter: adapterModule,
    ws: wsModule,
    playwallet: playwalletModule,
},

state: {

    notification: null,
    information: null,
    
    // GET PARAMS //
    utmSource: '',
    utmCampaign: '',
    utmMedium: '',

    codeInvite: '',

    isMobile: null,

    // SESSION AND AUTH //
    user: null,
    sessionToken: localStorage.getItem('sessionToken'),
    jwt: localStorage.getItem('jwt'),
    refreshToken: localStorage.getItem('refreshToken'),
    userAccess: null,

    // GENERAL DATA //
    appData: null,
    appLoaded: false,
    appError: false,
    appErrorCode: null,
    appErrorText: null,

    modals: {
        walletCreate: { 
            show: false,
            coinId: null
        },

    },

    requireRefresh: false,
    lastCoinUpdate: null,
    walletsNeedRefresh: false,

    cookieConsentNeeded: false,
    cookieConsentGiven: false,

},
mutations: {

    SET_NOTIFICATION(state, notification) {
        state.notification = notification;
    },
    SET_INFORMATION(state, information) {
        state.information = information;
    },

    UPDATE_COIN_IN_LIST(state, coinData) {
        state.lastCoinUpdate = coinData;
    },

    // UTM PARAMS //
    SET_UTM_DATA(state, { source, campaign, medium }) {
        // alert('SET_UTM_DATA: '+source+' '+campaign+' '+medium); // !!
        state.utmSource = source;
        state.utmCampaign = campaign;
        state.utmMedium = medium;
    },

    SET_CODE_INVITE(state, code) {
        // Validate code format - only letters and numbers, max 30 chars
        const sanitizedCode = code ? code.replace(/[^a-zA-Z0-9]/g, '').slice(0, 30) : '';
        state.codeInvite = sanitizedCode;
        localStorage.setItem('codeInvite', sanitizedCode);
    },

    SET_IS_MOBILE(state, isMobile) {
        state.isMobile = isMobile;
    },

    // SESSION AND AUTH //

    // SET_USER(state, user) {
    //     // alert("SET USER = "+JSON.stringify(user)); // !!
    //     console.log('SET user: '+JSON.stringify(user)); // !!
    //     state.user = user;
    // },
    SET_USER(state, userData) {
        // console.log('[store/commit] SET_USER with userData: '+JSON.stringify(userData)); // !!
        if(userData.user_lvl && userData.user_lvl>1) state.userAccess = true; else state.userAccess = false;
        state.userData = userData;
        if(userData.user_access == 1) state.userAccess = true; else state.userAccess = false;
        state.user = true;
    },  
    // SET_USER_ACCESS(state, userAccess) {
    //     state.userAccess = userAccess;
    // },
    SET_JWT(state, token) {
        state.jwt = token;
        if (token) {
            localStorage.setItem('jwt', token);
        } else {
            localStorage.removeItem('jwt');
        }
    },
    SET_REFRESH_TOKEN(state, token) {
        state.refreshToken = token;
        if (token) {
            localStorage.setItem('refreshToken', token);
        } else {
            localStorage.removeItem('refreshToken');
        }
    },
    SET_SESSION_TOKEN(state, token) {
        state.sessionToken = token;
        console.log('SET sessionToken: '+token);
        localStorage.setItem('sessionToken', token);
    },
    CLEAR_AUTH(state) {
        state.user = null;
        state.userData = null;
        state.jwt = null;
        state.refreshToken = null;
        state.walletConnected = false;
        state.userAccess = null;
        localStorage.removeItem('jwt');
        localStorage.removeItem('refreshToken');
        // Clear playwallet list
        this.commit('playwallet/clearPlayWallet');
    },

    // GENERAL DATA //
    SET_APP_DATA(state, data) {
        state.appData = data;
    },
    SET_APP_LOAD(state, load) {
        state.appLoaded = load;
    },
    SET_APP_ERROR(state, { code, text }) {
        state.appError = true;
        state.appErrorCode = code;
        state.appErrorText = text;
    },

    SET_WALLETS_REFRESH(state, value) {
        state.walletsNeedRefresh = value;
    },

    SET_COOKIE_CONSENT_NEEDED(state, value) {
        state.cookieConsentNeeded = value;
    },
    
    SET_COOKIE_CONSENT_GIVEN(state, value) {
        state.cookieConsentNeeded = false;
        state.cookieConsentGiven = value;
        localStorage.setItem('cookieConsent', 'true');
    },

  },
  actions: {

    ...sessActions,
    ...authActions,
    ...userActions,

    // GET PARAMS //
    async setGetParams({ commit }, { utmSource = '', utmCampaign = '', utmMedium = '', inviteCode = null }) {
        // console.log('setGetParams: '+utmSource+' '+utmCampaign+' '+utmMedium+' '+inviteCode); // !!
        commit('SET_UTM_DATA', { source: utmSource, campaign: utmCampaign, medium: utmMedium });
        // if(botCode) commit('SET_CODE_BOT', botCode);
        if(inviteCode) commit('SET_CODE_INVITE', inviteCode);
    },

    // NOTIFICATIONS //

    showNotification({ commit }, { message, type }) {
      commit('SET_NOTIFICATION', { message, type });
      setTimeout(() => {
        commit('SET_NOTIFICATION', null);
      }, 5000);
    },
    hideNotification({ commit }) {
        setTimeout(() => {
            commit('SET_NOTIFICATION', null);
        }, 1);
    },
    
    showInformation({ commit }, { message, type }) {
      commit('SET_INFORMATION', { message, type });
    },
    hideInformation({ commit }) {
        setTimeout(() => {
            commit('SET_INFORMATION', null);
        }, 1);
    },

    // SOCKET ALERTS //

    updateCoinList({ commit }, updatedCoin) {
        // This action will be called from the WebSocket module
        // You can implement any global state updates here if needed
        // For now, we'll just pass it through to the component
    },

    handleCoinUpdate({ commit, dispatch }, updatedCoin) {
        console.log('why here?');
        commit('UPDATE_COIN_IN_LIST', updatedCoin);
    },

    updateCoinInList({ commit }, coinData) {
        commit('UPDATE_COIN_IN_LIST', coinData);
    },

    triggerWalletsRefresh({ commit }) {
        commit('SET_WALLETS_REFRESH', true);
    },

    // HELPERS //

    checkMobile({ commit }) {
        commit('SET_IS_MOBILE', /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },

  },
});

export default store;