<template>

    <div @click="hideAuth()" class="fixed inset-0 bg-black backdrop-blur-sm bg-opacity-45 z-[800] flex flex-col h-full py-8 overflow-y-auto">
    <!-- <div @click="hideAuth()" class="fixed inset-0 bg-slate-900 backdrop-blur-sm bg-opacity-75 z-[800] flex flex-col h-full py-8 overflow-y-auto"> -->

        <div @click.stop class="bg-slate-800 md:p-6 p-4 shadow-xl shadow-slate-950
        rounded-sm border border-white border-opacity-10
        
         min-w-[320px] w-[80%] max-w-[500px] mx-auto my-auto ">
            <div class="flex flex-row justify-between">
                <h2 class="font-bold mb-3">Sign In</h2>
                <div @click="hideAuth()" class="p2 cursor-pointer" ><img src="@/assets/set/close.svg" class="w-5 h-5"></div>
                <!-- border-slate-400 rounded-full -->
            </div>

            <div v-if="isLoading" class="flex flex-col justify-center gap-3 border border-slate-600 p-5 rounded-xl mb-4 text-center min-h-[250px]">
                <div class="spinner-32 mx-auto"></div>
                <h3 class="font-bold">Loading...</h3>
            </div>
            <div v-else>
                    
                <div class="mb-5 text-lg flex flex-row gap-3 justify-between">
                    <div>
                        Log in or register your account.
                        <!-- Authorise your account or create a new one: -->
                    </div>
                    <div class="w-fit font-mono text-xs c2 opacity-30 bg-slate-950 px-2 py-1 rounded-md" v-if="codeInvite">
                        {{ codeInvite }}
                    </div>
                </div>
                
                <!-- // Forms // -->

                <div v-if="signEmail">

                    <div v-if="signStep==1" class="flex flex-col gap-3 border border-slate-600 p-5 rounded-xl mb-4">
                        <h3 class="font-bold mb-0">E-mail</h3>
                        <!-- <div class="uppercase text-sm c2">E-mail</div> -->
                        <input type="text" class="text placeholder:text-slate-500" v-model="formData.email" placeholder="Enter your e-mail">
                        <div class="text-slate-400 text-sm">An access code will be sent to this address.</div>
                        <button target="_blank" class="b3 bc w-fit" @click="initEmail()" :disabled="isSubmit">
                            <span v-if="!isSubmit">Continue</span>
                            <span v-else>Sending...</span>
                        </button>
                        <!-- <div class="text-slate-400 text-sm">Only whitelisted accounts accepted at this time.</div> -->
                    </div>

                    <div v-if="signStep==2" class="flex flex-col gap-3 border border-slate-600 p-5 rounded-xl mb-4">
                        <h3 class="font-bold">Verify Code</h3>
                        <div>Enter the code sent to your e-mail:</div>
                        <input type="text" class="text" v-model="formData.code">
                        <button target="_blank" class="b3 bc w-fit" @click="sendVerify()" :disabled="isSubmit">
                            <span v-if="!isSubmit">Confirm</span>
                            <span v-else>Sending...</span>
                        </button>
                        <div>
                            <button @click="resendCode" :disabled="sendIn > 0" :class="{ 'opacity-50': sendIn > 0 }">
                                Resend code
                            </button>
                            <span class="text-slate-400 text-sm ml-2" v-if="sendIn > 0">(in {{ sendIn }} sec)</span>
                        </div>
                    </div>

                </div>

                <div v-if="signTelegram" class="flex flex-col gap-3 border border-slate-600 p-4 rounded-xl mb-4">
                    <h3 class="font-bold">Telegram</h3>
                    <div class="">Open the Telegram bot:</div>
                    <a v-if="telegramAuthLink" :href="`${telegramAuthLink}`" target="_blank" class="b3 bc w-fit">
                        <span>Open <img src="@/assets/social/t-b.svg" class="w-5 h-5 inline-block mb-1 mx-1"> Telegram</span>
                    </a>
                    <!-- <div class="text-xs text-slate-400">Use <a href="https://t.me/airpumpbot" target="_blank" class="underline">Telegram</a> messanger to authentificate.</div> -->
                    <div class="text-sm text-slate-400">Start the bot and confirm authorization by pressing the button.</div>
                    <div class="text-xs text-slate-400">Use Airpump as Telegram miniapp or comfirm authentification in the browser.</div>
                </div>

                <div v-if="signWallet" class="flex flex-col gap-0 border border-slate-600 rounded-xl mb-4">

                    <div v-if="isPhantomSigning || isSolflareSigning || isMetamaskSigning" class="mb-3 text-slate-300 border border-slate-600 p-3 rounded-xl">Signing...</div>

                    <div  class="border-b border-slate-600 pb-5 p-5 flex flex-col gap-3">

                        <!-- <div class="mb-2">Unlock and connect your browser wallet extention to continue:</div> -->
                        <h3 class="mb-2 font-bold">Connect Wallet</h3>
                        
                        <!-- <h4 class="mb-1 c3">Ethereum</h4> -->
                        <button @click="connectMetamask()" :disabled="isConnecting" class="b2 ba">
                            <div class="flex flex-row gap-2 justify-between w-full">
                                <span v-if="isConnecting && walletType === 'metamask'">Connecting...</span>
                                <span v-else-if="isMetamaskSigning">Awaiting Signature...</span>
                                <!-- <span v-else>{{ isPhantomInstalled ? 'Connect Phantom' : 'Install Phantom' }}</span> -->
                                <span v-else class="font-bold">Metamask</span>
                                <span class="c2 text-sm">
                                    <!-- <img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block">  -->
                                    <span v-if="!isMetamaskInstalled" class=" flex flex-row items-center gap-2 text-slate-400">Not detected</span>
                                    <span v-else class=" flex flex-row items-center gap-2 font-bold c1"><img src="@/assets/set/check.svg" class="w-5 h-5 inline-block"> Detected</span>
                                </span>
                            </div>
                        </button>
                        
                    <!-- </div>
                    <div  class="border-b border-slate-600 pb-5 p-5 flex flex-col gap-3">

                        <h4 class="mb-1 c3">Solana</h4> -->
                        <button @click="connectPhantom()" :disabled="isConnecting" class="b2 ba">
                            <div class="flex flex-row gap-2 justify-between w-full">
                                <span v-if="isConnecting && walletType === 'phantom'">Connecting...</span>
                                <span v-else-if="isSigning && walletType === 'phantom'">Awaiting Signature...</span>
                                <!-- <span v-else>{{ isPhantomInstalled ? 'Connect Phantom' : 'Install Phantom' }}</span> -->
                                <span v-else class="font-bold">Phantom</span>
                                <div v-if="isPhantomWallet" class="text-xs text-slate-400">(n)</div>
                                <div v-if="isMobile" class="text-xs text-slate-400">(m)</div>
                                <span class="c2 text-sm">
                                    <!-- <img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block">  -->
                                    <span v-if="!isPhantomInstalled" class=" flex flex-row items-center gap-2 text-slate-400">Not detected</span>
                                    <span v-else class=" flex flex-row items-center gap-2 font-bold c1"><img src="@/assets/set/check.svg" class="w-5 h-5 inline-block"> Detected</span>
                                </span>
                            </div>
                        </button>
                        <!-- <div v-if="!isPhantomInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Phantom wallet not detected</div> -->

                        <button @click="connectSolflare()" :disabled="isConnecting" class="b2 ba">
                            <div class="flex flex-row gap-2 justify-between w-full">
                                <span v-if="isConnecting && walletType === 'solflare'">Connecting...</span>
                                <span v-else-if="isSigning && walletType === 'solflare'">Awaiting Signature...</span>
                                <!-- <span v-else>{{ isSolflareInstalled ? 'Connect Solflare' : 'Install Solflare' }}</span> -->
                                <span v-else class="font-bold">Solflare</span>
                                <!-- <div v-if="isSolflareWallet" class="text-xs text-slate-400">(n)</div>
                                <div v-if="isMobile" class="text-xs text-slate-400">(m)</div> -->
                                <span class="c2 text-sm">
                                    <span v-if="!isSolflareInstalled" class=" flex flex-row items-center gap-2 text-slate-400">Not detected</span>
                                    <span v-else class=" flex flex-row items-center gap-2 font-bold c1"><img src="@/assets/set/check.svg" class="w-5 h-5 inline-block"> Detected</span>
                                </span>
                            </div>
                        </button>
                        
                        <button v-if="isMobile" @click="openInPhantom()" class="b4 ba">Open in Phantom's built-in browser</button>
                        
                        <!-- <button @click="connectAdapter()" :disabled="isConnecting" class="b3 bc">
                            <span v-if="isConnecting && walletType === 'adapter'">Connecting...</span>
                            <span v-else-if="isSigning && walletType === 'adapter'">Awaiting Signature...</span>
                            <span v-else class="font-bold">Mobile Wallet</span>
                        </button>
                        <div v-if="!isAdapterInstalled" class="mb-2 text-slate-300"><img src="@/assets/set/warn.svg" class="w-5 h-5 inline-block"> Mobile wallet not detected</div> -->

                    </div>
                </div>

                <div class="flex items-center mb-5">
                        <div class="flex-grow h-px bg-slate-600"></div>
                        <div class="mx-4 text-slate-300">or continue with</div>
                        <div class="flex-grow h-px bg-slate-600"></div>
                    </div>

                <!-- // Selector // -->
                <div class="flex flex-col gap-5">

                    <button v-if="!signEmail" @click="switchForm('Email')"  class="bx b2 text-center">Log In with E-mail</button>
                    <button v-if="!signTelegram" @click="switchForm('Telegram')" class="bx b2">Log In <img src="@/assets/soc/c3/tg.svg" class="w-5 h-5 inline-block"> Telegram</button>
                    <button v-if="!signWallet" @click="switchForm('Wallet')"  class="b2 bx">Connect <img src="@/assets/set/sol.svg" class="w-4 h-4 mx-1 inline-block"> Wallet</button>

                </div>
                <!-- <div v-else>
                    <button @click="showSelector()"  class="b4 bx">Other Methods</button>
                </div> -->

            </div>

            <div class="mt-5 text-slate-500 text-sm">
                By signing in, you agree to
                <a href="/docs/terms" target="_blank" class="underline">Terms of Service</a>
                 and <a href="/docs/privacy" target="_blank" class="underline">Privacy Policy</a>.
            </div>

        </div>

    </div> 

</template>

<script>
import { mapState, mapActions } from 'vuex';
import api from '@/api';

export default {
    name: 'Airpump - Sign In',
    data() {
        return {

            DEBUG: false,
            isLoading: false,
            signStep: 1,
            sendIn: 0,
            sendInNext: 30, // 30 sec
            resendTimer: null,
            isSubmit: false,
            signEmail: true,
            signWallet: false,
            signTelegram: false,
            telegramAuthCode: 'A_test',
            telegramAuthLink: null,
            sessionCode: null,
            isPhantomWallet: false,
            isSolflareWallet: false,
            formData: {
                email: '', // TODO: on local make it test@test.com
                code: '',
            },

        }
    },
    computed: {

        ...mapState('wallets', ['isConnecting', 'isConnected', 'walletNetwork', 'walletType', 'walletData', 'walletAddr', 'isSigning']),
        ...mapState('metamask', {isMetamaskInstalled: 'isInstalled', isMetamaskSigning: 'isSigning', metamaskChainName: 'chainName' }),
        ...mapState('phantom', {isPhantomInstalled: 'isInstalled', isPhantomSigning: 'isSigning' }),
        ...mapState('solflare', {isSolflareInstalled: 'isInstalled', isSolflareSigning: 'isSigning' }),
        // ...mapState('adapter', {isAdapterInstalled: 'isInstalled', isAdapterSigning: 'isSigning' }),
        // ...mapGetters('wallets', [ 'isAnyWalletConnected', 'connectedWalletType', 'connectedPublicKey' ]),
        ...mapState(['sessionToken', 'utmSource', 'utmCampaign', 'utmMedium', 'codeInvite']),
        ...mapState(['isMobile']),
    },
    created() {
        this.checkWalletBrowser();
    },
    methods: {

        ...mapActions(['showNotification', 'afterAuth']),
        ...mapActions('playwallet', ['fetchWallets']),

        //* Email Auth *//

        async initEmail(resend = false) { // Added resend parameter with default value false
            this.isSubmit = true;
            try {
                if(!this.checkEmail(this.formData.email)) {
                    this.showNotification({ type: 'error', message: 'Please enter valid e-mail address' });
                    return;
                }
                const response = await api.post('auth/email/init', 
                    { data:
                        {
                            email: this.formData.email,
                            code_invite: this.codeInvite,
                            // UTM now retreived from session
                            // utm_source: this.utmSource,
                            // utm_campaign: this.utmCampaign,
                            // utm_medium: this.utmMedium,
                            resend: resend // Include the resend parameter in the request
                        }
                    },
                );

                if(response.data.result=='success') {
                    this.formData.code=response.data.sign_code; // !! WARNING! Remove this!
                    this.showNotification({ type: 'success', message: 'Please check your e-mail' });
                    this.signStep=2;
                    this.startResendTimer();
                    return;
                }
                
            } catch (error) {
                console.error('Failed to fetch coins:', error);
                this.showNotification({ type: 'error', message: 'Failed to authorise: '+error });
                // Handle error (e.g., show notification)
            } finally {
                this.isSubmit = false;
            }
        },

        startResendTimer() {
            this.sendIn = this.sendInNext;
            this.resendTimer = setInterval(() => {
                if (this.sendIn > 0) {
                    this.sendIn--;
                } else {
                    clearInterval(this.resendTimer);
                }
            }, 1000);
        },

        async resendCode() {
            if (this.sendIn > 0) return;
            await this.initEmail(true); // Call initEmail with resend=true
        },

        openInPhantom() {
            const currentUrl = encodeURIComponent(window.location.origin);
            const phantomUrl = `https://phantom.app/ul/browse/${currentUrl}?ref=${currentUrl}`;
            window.open(phantomUrl); // , '_blank'
        },

        async sendVerify() { // => move to store as authEmailVerify
    
            this.isSubmit = true;
            this.isLoading = true;
            try {

                const codeRegex = /^\d{6}$/;
                if (!codeRegex.test(this.formData.code)) {
                    this.showNotification({ type: 'error', message: 'Invalid code' });
                    return;
                }
               
                const response = await api.post('auth/email/verify', 
                    {  
                        data: { 
                            email: this.formData.email,
                            code: this.formData.code
                        }
                    },
                );
                // alert(JSON.stringify(response.data));
                if(response.data.result=='success') {
                    this.showNotification({ type: 'success', message: 'Welcome to Airpump!' });
                 
                    if(response.data.user_data) this.$store.commit('SET_USER', response.data.user_data);
                    // if(response.data.user_auth) {
                    //     this.$store.commit('SET_JWT', response.data.jwt);
                    //     this.$store.commit('SET_REFRESH_TOKEN', response.data.refresh_token );
                    // }

                    if (response.data.jwt && response.data.refresh_token) {
                        // alert('commit jwt'+response.data.jwt);
                        this.$store.commit('SET_JWT', response.data.jwt);
                        this.$store.commit('SET_REFRESH_TOKEN', response.data.refresh_token);
                    }

                    this.afterAuth();
                    this.hideAuth(); // shall be done automatically as user is changed to true

                    // this.$emit('showAccess');
                    // 
                    // router.push({ name: 'dashboard' });
                    //
                    return;
                }
                
            } catch (error) {
                console.error('Failed to fetch coins:', error);
                this.showNotification({ type: 'error', message: 'Failed to authorise: '+error });
                // Handle error (e.g., show notification)
            } finally {
                this.isSubmit = false;
                this.isLoading = false;
            }
            // this.showNotification({ type: 'info', text: 'Sending form...' });
        },

        //* Wallet Auth *//

        ...mapActions('wallets', ['connectWallet', 'disconnectWallet']),

        // !! TO DO:
        // if isMobile and has provider - it is in the Phantom wallet browser!
        
        async connectMetamask() {
            if (!this.isMetamaskInstalled) {
                this.openWalletInstallPage('metamask');
            } else {
                try {
                    await this.connectWallet({ walletType: 'metamask', justAdd: false, deeplink: false });
                } catch (error) {
                    this.$store.dispatch('showNotification', {
                        message: 'Failed to initiate wallet connection. Please try again.',
                        type: 'error'
                    });
                    this.connectError=error;
                    // The error notification is already handled in the store action
                    // Update the signing status here if needed
                    // this.$store.commit('metamask/SET_SIGNING', false);
                }
            }
        },

        async connectPhantom() {
            if (!this.isMobile && !this.isPhantomInstalled) { // not show install page on mobile
                this.openWalletInstallPage('phantom');
            } else { 
                // Use direct provider interaction if we're in Phantom browser, otherwise use deeplink
                const useDeeplink = this.isMobile && !this.isPhantomWallet;
                await this.connectWallet({ 
                    walletType: 'phantom', 
                    mode: 'auth', 
                    justAdd: false, 
                    deeplink: useDeeplink 
                });
            }
        },
        
        async connectSolflare() {
            if (!this.isMobile && !this.isSolflareInstalled) {  // not show install page on mobile
                this.openWalletInstallPage('solflare');
            } else {
                // Use direct provider interaction if we're in Solflare browser, otherwise use deeplink
                const useDeeplink = this.isMobile && !this.isSolflareApp();
                await this.connectWallet({ 
                    walletType: 'solflare', 
                    mode: 'auth', 
                    justAdd: false, 
                    deeplink: useDeeplink 
                });
            }
        },

        // async connectAdapter() {
        //     // if (!this.isadapterInstalled) {
        //         // this.openWalletInstallPage('adapter');
        //         // alert('No mobile wallet detected');
        //     // } else {
        //         await this.connectWallet('adapter');
        //     // }
        // },

        openWalletInstallPage(wallet) {
            let url;
            if (this.isMobile) {
                if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                    switch (wallet) {
                        case 'phantom':
                            url = 'https://apps.apple.com/app/phantom-solana-wallet/id1598432977';
                            break;
                        case 'solflare':
                            url = 'https://apps.apple.com/app/solflare-wallet/id1580902717';
                            break;
                        case 'metamask':
                            url = 'https://apps.apple.com/us/app/metamask-blockchain-wallet/id1438144202';
                            break;
                    }
                } else if (/Android/i.test(navigator.userAgent)) {
                    switch (wallet) {
                        case 'phantom':
                            url = 'https://play.google.com/store/apps/details?id=app.phantom';
                            break;
                        case 'solflare':
                            url = 'https://play.google.com/store/apps/details?id=com.solflare.mobile';
                            break;
                        case 'metamask':
                            url = 'https://play.google.com/store/apps/details?id=io.metamask';
                            break;
                    }
                }
            } else {
                switch (wallet) {
                    case 'phantom':
                        url = 'https://phantom.app/';
                        break;
                    case 'solflare':
                        url = 'https://solflare.com/';
                        break;
                    case 'metamask':
                        url = 'https://metamask.io/download/';
                        break;
                }
            }
            if (url) {
                window.open(url, '_blank');
            }
        },
        // isMobile() {
        //     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        // },

        //* Telegram Auth *//

        async initTelegram() { // Added resend parameter with default value false
            this.isSubmit = true;
            this.isLoading = true;
            try {
           
                const response = await api.post('auth/telegram/session');
                // alert(JSON.stringify(response.data));
                if(response.data.result=='success') {
                    this.sessionCode=response.data.session_code; // !! WARNING! Remove this!
                } else {
                    this.sessionCode=null;
                }

                this.telegramAuthLink = `https://t.me/${process.env.VUE_APP_TELEGRAM_BOT}?start=login__${this.sessionCode || 'no'}__${this.codeInvite || 'no'}`;
                
            } catch (error) {
                console.error('Failed to fetch coins:', error);
                this.showNotification({ type: 'error', message: 'Failed to authorise: '+error });
                // Handle error (e.g., show notification)
            } finally {
                this.isSubmit = false;
                this.isLoading = false;
            }
        },

        //* Modal Control *//

        showConnect() {
            this.$emit('showConnect');
        },
        hideAuth() {
            this.$emit('hideAuth');
        },

        switchForm(form) {
            if(form=='Email') {
                this.signEmail=true;
                this.signTelegram = false;
                this.signWallet = false;
            }
            if(form=='Telegram') {
                this.initTelegram();
                this.signEmail=false;
                this.signTelegram = true;
                this.signWallet = false;
            }
            if(form=='Wallet') {
                this.signEmail=false;
                this.signTelegram = false;
                this.signWallet = true;
            }
        },

        checkWalletBrowser() {
            const isInPhantom = this.isPhantomApp();
            const isInSolflare = this.isSolflareApp();
            
            if (isInPhantom || isInSolflare) {
                this.$store.commit('SET_MOBILE', false);
                this.isPhantomWallet = isInPhantom;
                this.isSolflareWallet = isInSolflare;
                this.switchForm('Wallet');
            }
        },
        
        isPhantomApp() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            // More specific check for Phantom's user agent
            return /Phantom\/(?:ios|android)/i.test(userAgent);
        },

        isSolflareApp() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            return /Solflare-Mobile/i.test(userAgent);
        },
    },
    beforeUnmount() {
        if (this.resendTimer) {
            clearInterval(this.resendTimer);
        }
    }
}
</script>
